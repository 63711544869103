import { getPageWithFiltersServerSideProps } from '~/utils/server-side-props/pages-with-filters';
import { FiltersPageContent, FiltersPageProps } from '~/components/filters-page';

const ThingsToDoInSaudiPage = (props: FiltersPageProps) => {
   return <FiltersPageContent {...props} />;
};

export const getServerSideProps = getPageWithFiltersServerSideProps;
ThingsToDoInSaudiPage.hideSearchbar = true;
export default ThingsToDoInSaudiPage;
